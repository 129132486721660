import type { NextPage } from 'next/types';

const NotFoundErrorPage: NextPage = () => {
  return (
    <>
      <h1>This link does nothing here</h1>
    </>
  );
};

export const getStaticProps = () => ({
  props: {
    title: '🕵🏼 - 404',
  },
});

export default NotFoundErrorPage;
